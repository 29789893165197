// extracted by mini-css-extract-plugin
export var appImage = "Apps-module--appImage--1Sylm";
export var showMobile = "Apps-module--showMobile--1XEVe";
export var hideMobile = "Apps-module--hideMobile--2qjcF";
export var appContainer = "Apps-module--appContainer--1V5_z";
export var appHeader = "Apps-module--appHeader--32XVH";
export var item = "Apps-module--item--2uovq";
export var positionFlex = "Apps-module--positionFlex--1H8aq";
export var appInfo = "Apps-module--appInfo--3CuGz";
export var btn = "Apps-module--btn--15EX1";
export var appAddInfo = "Apps-module--appAddInfo--1y93z";
export var headerImg = "Apps-module--headerImg--3uS_a";
export var phones = "Apps-module--phones--13fM7";
export var mobileSection = "Apps-module--mobileSection--3VVKS";
export var mobileContainer = "Apps-module--mobileContainer--1hzco";
export var mobileImg = "Apps-module--mobileImg--52vJP";
export var stores = "Apps-module--stores--2xZOg";
export var desktopSection = "Apps-module--desktopSection--248yu";
export var desktopContainer = "Apps-module--desktopContainer--23MLl";
export var downloadInfo = "Apps-module--downloadInfo--1NLJ1";
export var storeBtn = "Apps-module--storeBtn--112Kp";